#data_apply{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

#data_apply h2{
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 30px;
    font-family: helvetica;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.652);
}

#data_apply form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.row{
    margin-top: 2%;
    width: 98%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.row_two{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.row label{
    align-content: end;
    width: 60%;
    height: 30px;
    font-size:15px;
    font-family: helvetica;
    color: #000;
}

.row input{
    width: 100%;
    height: 30px;
    border: none;
    text-align: left;
    font-size: 15px;
    font-family: helvetica;
    color: #000;
    padding: 5px;
    border-bottom: 1px solid #000;
    background-color: transparent;
    outline: none;
}

.row select{
    width: 100%;
}


.classdateapply{
    width: 250px;
    height: 50px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid #161639;
    box-shadow: 0px 0px 100px #161639;
}

#button_end_apply{
    width: 20%;
    height: 35px;
    text-align: center;
    font-size: 20px;
    font-family: helvetica;
    color: #fff;
    padding: 5px;
    border: none;
    background-color: gray;
    outline: none;
}

#button_end_apply:hover{
    cursor: pointer;
}

#message_apply_done{
    position: absolute;
    top: 30%;
    left: 50%;
    text-align: center;
    font-size: 6vw;
    font-family: helvetica;
    color: #fff;
    padding: 10px;
    transform: translateX(-50%);
    background-color: rgb(50, 56, 56);
}

@media screen and (max-width: 1024px) {
    #data_apply h2{
        align-content: center;
        height: 60px;
        text-align: center;
        font-size: 35px;
    }

    #data_apply form{
        gap: 20px;
    }
    

    .row{
        margin-top: 5%;
        width: 100%;
        gap: 2px;
    }

    .row label{
        width: 80%;
        height: 40px;
        text-align: center;
        font-size: 1.8vw;
    }
    
    .row input{
        width: 100%;
        height: 40px;
        text-align: left;
        font-size: 1.8vw;
    }

    #button_end_apply{
        width: 100%;
        height: 45px;
    }
}

@media screen  and (max-width: 600px){
    #data_apply h2{
        height: 40px;
        font-size: 25px;
    }

    #data_apply form{
        gap: 25px;
    }
    

    .row{
        margin-top: 5%;
        width: 100%;
        gap: 2px;
    }

    .row label{
        width: 60%;
        font-size: 2vw;
    }
    
    .row input{
        width: 100%;
        height: 40px;
        text-align: left;
        font-size: 2vw;
    }
}
