#main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../img/background.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#backHome{
  width: 90%;
  height: 30%;
}

#img_logo_app{
    margin-top: 4%;
    width: 90%;
    height: 30%;
    background-image: url('../img/pixelcut-export.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  #h2_app{
    margin-top: 2%;
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-family: helvetica;
    color: #fff;
    background-color: #ffffff4b;
    padding: 5px;
  }
  
  #button_app{
    align-content: center;
    margin-top: 8%;
    width: 40vw;
    height: 20%;
    text-align: center;
    text-decoration: none;
    font-size: 4vw;
    font-family: helvetica;
    border: none;
    background-color: #ffffff4b;
    color: #fff;
    outline: none;
  }

  #button_app:hover{
    cursor: pointer;
  }


  /*Mediaqueries for tablet*/
  @media screen and (max-width: 1024px){
    #h2_app{
      margin-top: 3%;
      font-size: 6vw;
    }
    
    #button_app{
      margin-top: 13%;
      height: 10%;
      font-size: 4vw;
    }
  }

    /*Mediaqueries for Movil*/
    @media screen and (max-width: 650px){
      #h2_app{
        margin-top: 5%;
        font-size: 6vw;
      }
      
      #button_app{
        margin-top: 20%;
        height: 10%;
        font-size: 4vw;
      }
    }